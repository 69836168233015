import { getPoolStats } from './api';
import _get from 'lodash/get';
import _reduce from 'lodash/reduce';
import _sortBy from 'lodash/sortBy';
import { COIN_SEQUENCE } from '@/processes/Router';

let isCoinLoadedFirstTime = null as any;

export const selectPoolStatsRaw = (state: any) => { return getPoolStats.select(null)(state) ?? {}; }

export const selectPoolStats = (state: any) => { return selectPoolStatsRaw(state)?.data ?? {}; }

export const selectPoolStatsByCoin = (state: any, coin = 'BTC') => { 
  const data = selectPoolStats(state);
  return _get(data, `stats.${coin}`, {}); 
}

export const selectExchangeRate = (state: any, coin = 'BTC') => { 
  const data = selectPoolStats(state);
  return _get(data, `stats.${coin}.exchangeRates.USD`, 0); 
}

export const selectThreshold = (state: any, coin = 'BTC') => { 
  const data = selectPoolStats(state);
  return _get(data, `stats.${coin}.threshold`, 0); 
}

export const selectCryptoCurrencies = (state: any) => {
  const data = selectPoolStats(state);
  return _get(data, `cryptoCurrencies`, []);
}

export const selectCryptoCurrency = (state: any, coin = 'BTC') => {
  const data = selectPoolStats(state);
  return _get(data, `cryptoCurrencies`, []).find((item: any) => item.name === coin) || {};
}

export const selectPayoutAt = (state: any, coin = 'BTC') => { 
  const data = selectCryptoCurrency(state, coin);
  return _get(data, `payoutAt`, null);
}

export const selectProfitPerPower = (state: any, coin = 'BTC') => { 
  const data = selectCryptoCurrency(state, coin);
  return _get(data, `profitPerPower`, null);
}

export const selectExplorer = (state: any, coin = 'BTC') => { 
  const data = selectCryptoCurrency(state, coin);
  const { base, tx } = _get(data, `explorer`, {});
  return `${base}${tx}`;
}

export const selectCoinsStats = (state: any) => {
  const data = selectPoolStats(state);
  return _get(data, `stats`, {});
}

type CoinOrderMap = { [key: string]: number };

export const selectCoins = (state: any): string[] => {
  const data = selectPoolStats(state);
  const coins: string[] = _get(data, `cryptoCurrencies`, []).map((c: { name: string }) => c.name)

  // Create a map for the coin sequence
  const coinOrderMap: CoinOrderMap = _reduce(COIN_SEQUENCE, (acc: CoinOrderMap, coin: string, index: number) => {
    acc[coin] = index;
    return acc;
  }, {});

  // Sort coins based on the COIN_SEQUENCE
  return _sortBy(coins, (coin: string) => coinOrderMap[coin] ?? Infinity);
}

export const selectIsCoinsLoaded = (state: any) => { 
  // fix for HIP-881
  const isSuccess = isCoinLoadedFirstTime || _get(selectPoolStatsRaw(state), 'isSuccess', false);
  if (typeof isCoinLoadedFirstTime !== 'boolean' && isSuccess) {
    isCoinLoadedFirstTime = isSuccess;
  }
  return isSuccess;
}
