export const POOLS = {
  'BTC': {
    title: 'Bitcoin',
    showAddressExplorer: false,
    showProfitCalculator: true,
    isAuthRequired: true,
    isAccounting: true,
    fee: 2.5,
  },
  'GRAM': {
    title: 'Gram',
    showAddressExplorer: true,
    showProfitCalculator: true,
    isAuthRequired: false,
    isAccounting: true,
    isAnonymus: true,
    regexp: '^.{20,100}$', // ^[a-zA-Z0-9-]{20,100}$
    fee: 30,
  },
  'ETC': {
    title: 'Ethereum Classic',
    showAddressExplorer: true,
    showProfitCalculator: true,
    isAuthRequired: false,
    regexp: '^(0x)?[a-fA-F0-9]{40}$',
    isAccounting: false,
  },
  'RVN': {
    title: 'Ravencoin',
    showAddressExplorer: true,
    showProfitCalculator: true,
    isAuthRequired: false,
    regexp: '^[a-zA-Z0-9]{26,35}$',
    isAccounting: false,
  },
  'KAS': {
    title: 'Kaspa',
    showAddressExplorer: false,
    showProfitCalculator: false,
    isAuthRequired: false,
    regexp: '^kaspa:[a-z0-9]{55,100}$',
    isAccounting: true,
    isAnonymus: true,
    fee: 1,
    minimalHashrateToShow: 1e12 * 5 // 5TH
  },
  'DNX': {
    title: 'Dynex',
    showAddressExplorer: true,
    showProfitCalculator: true,
    isAuthRequired: false,
    regexp: '^[a-zA-Z0-9]{55,100}$',
    isAccounting: true,
    isAnonymus: true,
    fee: 1,
  },
};

export default POOLS;
