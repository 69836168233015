import { useRouter } from 'next/router';
import _toLower from "lodash/toLower";
import _get from "lodash/get";
import _omitBy from "lodash/omitBy";
import _isNil from "lodash/isNil";
import { DEFAULT_COIN, COIN_WITH_SUBACCOUNTS, COIN_ROUTES, COIN_LANDING, ACCOUNT_PATHES } from "../constants/common";
import { POOLS, isProtectedRoute } from '@/processes/Router';

// @ts-ignore
const getPoolLink = (params: any) => {
  const { pool, isDashboard = false, miner, account, support } = params;
  const landingUrl = _get(COIN_LANDING, _toLower(pool), '');
  const dashboardUrl = _get(ACCOUNT_PATHES, _toLower(pool), '');
  let link = `/${isDashboard ? dashboardUrl : landingUrl}`;
  if (isDashboard) {
    link += '/dashboard';
  }
  if (miner) {
    link += `?miner=${miner}`;
  } else if (account) {
    link += `?account=${account}`;
    if (support) {
      link += `&support=${support}`;
    }
  }
  return link;
};

export const useNavigation = (props = {}) => {
  const router = useRouter();
  const { locale = 'en', query } = router;
  const { pool = '', miner = '', account = '', support = null, view = null } = query;
  // coin with fallback to default coin
  const coin = _get(COIN_ROUTES, pool, DEFAULT_COIN);
  // coin without fallback
  const exactCoinMatch = _get(COIN_ROUTES, pool, null);
  const coinTitle = _get(POOLS, `${coin}.title`, '');
  const fee = _get(POOLS, `${coin}.fee`, 0);
  const minimalHashrateToShow = _get(POOLS, `${coin}.minimalHashrateToShow`, 1);
  const isAccounting = _get(POOLS, `${coin}.isAccounting`, false);
  const isDashboard = _get(router, 'pathname', '').includes('/dashboard');
  const landingUrl = _get(COIN_LANDING, _toLower(coin), '');

  return {
    router,
    isProtectedRoute: isProtectedRoute(),
    fee,
    minimalHashrateToShow,
    isAccounting,
    isDashboard,
    locale,
    query,
    coin,
    landingUrl,
    coinTitle,
    coinLower: _toLower(coin), 
    exactCoinMatch,
    view,
    account,
    miner,
    isBTC: coin === 'BTC',
    isRVN: coin === 'RVN',
    isETH: coin === 'ETH',
    isETC: coin === 'ETC',
    isKAS: coin === 'KAS',
    isDNX: coin === 'DNX',
    isGRAM: coin === 'GRAM',
    isSupportSubAccount: COIN_WITH_SUBACCOUNTS.includes(coin),
    isSha256: ['BTC', 'LTC'].includes(coin),
    poolLink: getPoolLink({ pool: coin, miner, account, support }),
    poolDashboardLink: getPoolLink({ pool: coin, isDashboard: true, miner, account, support }),
    getPoolLandingLink: (coin: string) => {
      return getPoolLink({ pool: coin });
    },
    getPoolDashboardLink: ({ miner, coin, account, support }: any) => {
      return getPoolLink({ pool: coin, isDashboard: true, miner, account, support });
    },
    navigate: (link: string, lang = '', options = {}) => {
      return router.push(link, undefined, Object.assign(
        { locale: lang || locale },
        options,
      ));
    },
    switchLocale: (lang = '') => {
      return router.push({
        pathname: router.pathname,
        query: router.query,
      }, undefined, { locale: lang || locale });
    },
    updateURLQueryParams: (newQueryParams = {}, merge = true, options = {}) => {
      const mergedQuery = _omitBy(merge ? { 
        ...router.query, 
        ...newQueryParams,
      } : newQueryParams, _isNil);
      router.push({
        pathname: router.pathname,
        query: mergedQuery,
      }, undefined, Object.assign({ shallow: true, scroll: false }, options));
    },
  }
};
